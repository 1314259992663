<template>
  <div class="text-center">
    <loading></loading>
    <div class="mt-4">
      {{ $t("appIsCreating") }}
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Shared/Loading.vue";
export default {
  components: {
    Loading,
  },

  data() {
    return {
      tryCount: 0,
    };
  },

  methods: {
    async CHECK_QUERY(query) {
      const q = query || this.$route.query;
      console.log(q, "qqqq");

      if (q.email && q.password) {
        const body = {
          email: q.email,
          password: q.password,
          fullname: q.name || "Heocademy Yönetim Paneli",
        };
        this.$router.replace({ query: {} });
        const response = await this.$api.post("Users/CreateExternalUser", body);
        console.log(response, "reesssponse");

        if (
          response.message === "OK" ||
          response.message === "USER ALREADY REGISTERED"
        ) {
          this.$router.push({ name: "SignIn", query: body });
        } else {
          console.log("buraya firmiyor mu");

          setTimeout(() => {
            if (this.tryCount > 15) return;
            this.CHECK_QUERY(body);
            this.tryCount += 1;
          }, this.tryCount * 1000);
        }
      } else {
        this.$router.push({ name: "SignIn" });
      }
    },
  },
  mounted() {
    this.CHECK_QUERY(this.$route.query);
  },
};
</script>

<style></style>
